require('./bootstrap-setup');
import AOS from 'aos';
import Flickity from 'flickity';
import 'flickity-as-nav-for';
import ModuloBox from './plugins/modulobox.js';

AOS.init();

// Remove background-img border
document.querySelectorAll('img:not([src])').forEach((element) => {
    element.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
});

// Add and remove class on scroll
if (document.querySelector('nav')) {
    let scrollpos = window.scrollY
    const header = document.querySelector("nav")
    const headerHeight = 40;

    const addClassOnScroll = () => header.classList.add("navbar-shrink", "shadow")
    const removeClassOnScroll = () => header.classList.remove("navbar-shrink", "shadow")

    window.addEventListener('scroll', function () {
        scrollpos = window.scrollY;

        if (scrollpos >= headerHeight) {
            addClassOnScroll()
        } else {
            removeClassOnScroll()
        }
    });
}

window.addEventListener('scroll', () => {
    const greenPlus = document.querySelector('.green-plus');
    const yellow = document.querySelector('.yellow');
    const orange = document.querySelector('.orange');

    greenPlus.style.transform = 'rotate(' + window.scrollY * 0.5 + 'deg)';
    yellow.style.transform = 'rotate(' + window.scrollY * 0.7 + 'deg)';
    orange.style.transform = 'rotate(' + window.scrollY * 0.4 + 'deg)';
})


if (document.querySelector('.carousel-text')) {
    const flkty3 = new Flickity('.carousel-text', {
        cellAlign: 'center',
        wrapAround: false,
        groupCells: false,
        asNavFor: '.carousel-timeline',
        contain: false,
        adaptiveHeight: true,
        pageDots: false,
        draggable: false,
        prevNextButtons: false,
        selectedAttraction: 0.08,
        friction: 0.6,
        initialIndex: 13
    });
}

if (document.querySelector('.carousel-timeline')) {
    const flkty4 = new Flickity('.carousel-timeline', {
        cellAlign: 'center',
        pageDots: false,
        contain: false,
        setGallerySize: false,
        wrapAround: false,
        prevNextButtons: true,
        initialIndex: 13
    });

    document.querySelectorAll('.timeline-btn').forEach(btn => {
        btn.addEventListener('click', event => {
            var index = btn.dataset.index;

            flkty4.select( index );
        });
    });
}

// Modulobox
var modulobox = new ModuloBox({
    mediaSelector: '.gallery figure > a, .hidden-gallery div',
    loop: 3,
    history: true,
    controls: ['zoom', 'play', 'fullScreen', 'download', 'share', 'close'],
    shareButtons: ['facebook', 'googleplus', 'twitter', 'pinterest', 'linkedin', 'reddit', 'stumbleupon', 'tumblr', 'blogger', 'buffer', 'digg', 'evernote'],
    videoMaxWidth: 1440,
    minZoom: 1,
    zoomTo: 1.8,
    mouseWheel: false,
    contextMenu: false,
    scrollToZoom: true,
    captionSmallDevice: false,
    thumbnails: true,
    thumbnailsNav: 'centered',
    thumbnailSizes: {
        1920: {
            width: 110,
            height: 80,
            gutter: 10
        },
        1280: {
            width: 90,
            height: 65,
            gutter: 10
        },
        680: {
            width: 0,
            height: 0,
            gutter: 0
        }
    }
});

modulobox.on('beforeOpen.modulobox', function (gallery, index) {
    if (window.cubeRAF) {
        cancelAnimationFrame(window.cubeRAF);
        window.cubeRAF = false;
    }
});

modulobox.on('afterClose.modulobox', function (gallery, index) {
    if (typeof Event === 'function' && !window.cubeRAF) {
        window.dispatchEvent(new Event('scroll'));
    }
});

modulobox.init();

// Menu
const btn = document.querySelector('.menu-toggler');

btn.addEventListener("click", toggleMenu);
const content = document.querySelector('.menu-content');

function toggleMenu() {

    if (content.classList.contains('open') && btn.classList.contains('opened')) {

        content.classList.toggle('open');

        btn.classList.remove('opened');
    } else {
        content.classList.add('open');
        btn.classList.add('opened');

    }
}

window.addEventListener('click', function (e) {
    if (!document.querySelector('.menu-toggler').contains(e.target) && (content.classList.contains('open'))) {

        content.classList.remove('open');
        btn.classList.remove('opened');
    }
})

// Smooth scroll to other page
if (window.location.hash) {
    setTimeout(() => {
        const hash = window.location.hash;
        if (document.querySelector(hash)) {
            const y = document.querySelector(hash).offsetTop -100;
            //const y = document.querySelector(hash).getBoundingClientRect().top + window.scrollY - 200;
            
            scroll({
                top: y,
                behavior: "smooth",
            });
        }
    }, 400);
}
